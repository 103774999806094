<template>
    <div>
        <v-alert
            border="right"
            colored-border
            color="secondary"
            type="info"
            elevation="1"
        >
        {{ title }}
        <div style="min-height:10px;"></div>
        <ul>
            <li>{{ text1 }}</li>
            <li>{{ text2 }}</li>
        </ul>
        </v-alert>
    </div>
</template>

<script>

export default {
    name: 'info-user-type-domacin',
    data: () => ({
        title: 'Izbrani tip kartice Gost:',
        text1: "samostojni podjetnik, fizična ali pravna oseba",
        text2: "kartico lahko kupijo tudi tisti, ki nimajo stalnega prebivališča v Občini Bled"
    })
}

</script>